<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Seats Management')}} > {{ $t('Seats') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Seats') }}</h2>
        <el-button class="action-button" type="primary">{{ $t('Add Seats') }}</el-button>
      </div>

      <div class="seat-list">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：1</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：2</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：3</h5>
            <p class="card-text">使用狀態： 不可用餐 (未開放)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：4</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：5</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：6</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：7</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：11</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：12</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：13</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：14</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：21</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：22</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：23</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：24</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：31</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：32</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：33</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：34</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：35</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：36</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：37</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：38</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：41</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：42</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：43</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：44</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：45</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：46</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：51</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：52</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：53</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：54</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：55</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：61V</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：62V</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：71</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：72</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：73</h5>
            <p class="card-text">使用狀態： 可用餐 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：74</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：75</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：76</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：77</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">檯號：78</h5>
            <p class="card-text">使用狀態： 用餐中 (開放中)</p>
            <a href="#" class="btn btn-primary">下單</a>
            <a href="#" class="btn btn-primary">拆枱</a>
            <a href="#" class="btn btn-primary">鎖枱</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Member from '@/lib/member';

export default {
  name: 'Members',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadAllMembers();
  },
  methods:{
    async loadAllMembers(){
      try{
        const loginInfo = Common.getLoginInfo();
        const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
        this.members = members;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      members: [],
      orignalMembers: [],
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.seat-list{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  .card{
    margin-bottom: 30px;
    margin-right: 20px;
    .btn-primary{
      margin-right: 30px;
    }
  }
}
</style>
